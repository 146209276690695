html, body {
  height: 100%;
}

.ws-button
{
  border-radius: 4px;
  padding: 5px;
  background: white;
  color:  #601e9e;
  border: 2px solid  #601e9e;
  &:hover {
    animation-duration: .1s;
    animation-timing-function: ease;
    animation-name: fromMiddle;
    color: white;
    background: #601e9e
  }
}

@keyframes fromMiddle {
  0% {
      background-image: linear-gradient(to right, white , white, white);
  }
  25% {
    background-image: linear-gradient(to right, #601e9e , white,white,white, white);
  }
  50% {
    background-image: linear-gradient(to right, #601e9e , #601e9e,white,white, white);
  }
  100%{
    background-image: linear-gradient(to right, #601e9e , #601e9e, #601e9e);
  }
}

#root {
  height: 100%;
  min-height: 100vh;
}
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #333; 
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #333; 
}