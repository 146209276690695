.mainElement {
    display: flex;
  align-items: center;
  flex-direction: column; 
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
    background-color: #003975;
}
.centerbox
{
    border-radius: 10px 10px 10px 10px;
    background: #fff;
    padding: 30px;
    width: 90%;
    max-width: 450px;
    position: relative;
    padding: 0px;
    box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
    padding: 50px;
}

.emptyInput
{
    color: #212529;
    background-color: #fff;
    border-color: #f00;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(255, 0, 0, 0.25);
}