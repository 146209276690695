#liveToast {
    z-index: 10;
}
.toast-header-red
{
    color: #fff!important;
    background-color: rgba(255, 0, 0, 0.57)!important;
}
.toast-body-red
{
    color: #fff!important;
    background-color: rgba(255, 0, 0, 0.57) !important;
}

.toast-header-green
{
    color: #fff !important;
    background-color: #28a745 !important;
}
.toast-body-green
{
    color: #fff !important;
    background-color: #28a745 !important;
}
.toast-header-blue
{
    background-color: rgba(0, 40, 255, 0.57) !important;
    color: #fff !important;
}
.toast-body-blue
{
    color: #fff;
    background-color: rgba(0, 40, 255, 0.57) !important;
}